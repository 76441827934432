import { Image, View } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';

export const CheckListItem = ({ designIcon, label, size, color }) => (
    <View
        style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 1,
        }}
    >
        {designIcon && (
            <Image
                source={designIcon}
                style={{ width: 14, height: 14, marginRight: 7 }}
            />
        )}
        <Text
            style={{
                fontSize: size,
                fontFamily: 'DMSans_400Regular',
                lineHeight: 20,
                letterSpacing: 0.4,
                color: color,
            }}
        >
            {label}
        </Text>
    </View>
);

CheckListItem.propTypes = {
    label: PropTypes.string,
    size: PropTypes.number,
    designIcon: PropTypes.any,
    color: PropTypes.string,
};
