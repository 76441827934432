import { colors, gradients, shadows, fontFamilySans } from './colors';
// import { StyleSheet } from 'react-native';
import { typography } from './typography.stylesheet';

export const ReactNavigationTheme = {
    colors: {
        background: 'white',
        border: '#ddd',
        primary: colors.textBody,
        text: colors.textBody,
    },
};

export const ReactNativeElementsTheme = {
    Text: {
        h1Style: {
            fontSize: 32,
            fontWeight: '400',
            color: colors.phthaloBlue,
            fontFamily: 'DMSans_400Regular',
        },
        h2Style: {
            fontFamily: 'DMSans_700Bold',
            fontSize: 24,
            color: colors.phthaloBlue,
            marginBottom: 15,
        },
        h3Style: {
            fontSize: 14,
            color: colors.fontBackground,
            opacity: 0.6,
            marginTop: 20,
            fontFamily: 'DMSans_500Medium',
            lineHeight: 22,
            letterSpacing: 0.1,
        },
        h4Style: {
            fontSize: 16,
            color: colors.phthaloBlue,
            fontFamily: 'DMSans_700Bold',
            lineHeight: 28,
            letterSpacing: 0.15,
            marginBottom: 15,
        },
        style: {
            color: colors.textBody,
            ...typography.regularFontWeight,
        },
    },
    Button: {
        containerStyle: { marginTop: 0 },
        disabledTitleStyle: { color: colors.disabledText },
        disabledStyle: {
            shadowOpacity: 0,
            backgroundColor: colors.disabledBg,
            borderColor: colors.disabledBg,
        },
    },
    Input: {
        containerStyle: {
            paddingHorizontal: 0,
            marginBottom: 10,
        },
        inputContainerStyle: {
            borderWidth: 1,
            borderColor: colors.phthaloBlue,
            borderRadius: 4,
            paddingHorizontal: 5,
        },
        labelStyle: {
            fontWeight: '400',
            fontSize: 16,
            marginBottom: 5,
            fontFamily: 'DMSans_400Regular',
            lineHeight: 19,
            color: 'rgba(0, 0, 0, 0.6)',
        },
        inputStyle: {
            minHeight: 48,
        },
        errorStyle: { color: colors.textError },
        rightIcon: { color: colors.phthaloBlue },
    },
    CheckBox: {
        containerStyle: {
            marginRight: 0,
            marginLeft: 0,
            paddingHorizontal: 0,
            borderWidth: 0,
            backgroundColor: 'transparent',
        },
        titleStyle: {
            fontFamily: 'DMSans_400Regular',
            fontSize: 16,
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: 0.15,
            lineHeight: 24,
        },
        textStyle: { color: colors.textBody },
    },
};

const styles = {
    lightBlueBorder: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderRadius: 10,
        padding: 20,
    },
    mediumBlueBorder: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderRadius: 10,
        padding: 20,
    },
    buttonStylePrimary: {
        backgroundColor: '#00008E',
        padding: 13,
        borderRadius: 4,
        shadowColor: '#00008E',
        borderWidth: 2,
        borderColor: '#00008E',
        shadowRadius: 5,
        shadowOpacity: 0.7,
        shadowOffset: { height: 5 },
    },
    titleStylePrimary: {
        color: colors.textPrimaryHeading,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.4,
        fontFamily: 'DMSans_500Medium',
    },
    buttonStyleSecondary: {
        backgroundColor: colors.yellow,
        padding: 13,
        borderRadius: 4,
        shadowColor: colors.yellow,
        borderWidth: 2,
        borderColor: colors.yellow,
        shadowRadius: 5,
        shadowOpacity: 0.7,
        shadowOffset: { height: 5 },
    },
    titleStyleSecondary: {
        color: colors.textHeading,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.4,
        fontFamily: 'DMSans_700Bold',
    },
    iosBackdrop: {
        backgroundColor: "#000000",
        opacity: 0.3
    },
    androidBackdrop: {
        backgroundColor: "#232f34",
        opacity: 0.32
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    textRegular: {
        fontSize: 16,
        fontFamily: 'DMSans_400Regular',
        lineHeight: 28,
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'capitalize',
        letterSpacing: 0.15
    },
    textBold: {
        fontSize: 16,
        fontFamily: 'DMSans_700Bold',
        lineHeight: 28,
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'capitalize',
        letterSpacing: 0.15,
    },
    textGradient: {
        fontSize: 16,
        fontFamily: 'DMSans_400Regular',
        alignSelf: 'flex-start',
        lineHeight: 28,
        color: 'rgba(0, 0, 0, 0.87)',
    },
    navigationButton: {
        fontFamily: 'DMSans_500Medium',
        color: "#00008E",
        fontSize: 14,
        lineHeight: 24,
        textAlign: 'center',
        textDecorationLine: 'underline',
        marginTop: 30
    }
};
/* const styles = StyleSheet.create({
    lightBlueBorder: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#CAE2E5',
        borderStyle: 'solid',
        paddingVertical: 15,
        paddingHorizontal: 15,
        borderRadius: 16,
    },
}); */

export default {
    typography,
    colors,
    shadows,
    fontFamilySans,
    gradients,
    styles,
    ReactNativeElementsTheme,
};
