import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';

export const TextCurrency = ({ value, ...props }) => {
    return (
        <NumberFormat
            displayType="text"
            value={value || 0}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={2}
            renderText={(v) => <Text {...props}>{v}</Text>}
        />
    );
};

TextCurrency.propTypes = {
    value: PropTypes.number,
};
