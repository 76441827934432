import * as Localization from 'expo-localization';
import { Alert, Text } from 'react-native';
import { AuthStateEnum, DefaultProfile, MainContext, DefaultBeneficiaryProfile } from './App.context';
import {
    DMSans_400Regular,
    DMSans_500Medium,
    DMSans_700Bold,
    useFonts
} from '@expo-google-fonts/dm-sans';
import React, { useEffect, useState } from 'react';
import theme, {
    ReactNativeElementsTheme,
    ReactNavigationTheme,
} from './src/common/ui/theme';
import { Auth0Provider } from './src/common/authentication/auth0.provider';
import { AuthenticationNavigator } from './src/navigation/authentication.navigator';
import { DrawerNavigator } from './src/navigation/drawer.navigator';
import { FailedKYCNavigator } from './src/navigation/failed-kyc.navigator';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import { NavigationContainer } from '@react-navigation/native';
import { OnboardingNavigator } from './src/navigation/onboarding.navigator';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import { ThemeProvider } from 'react-native-elements';
import { ToastDangerWithTitle } from './src/common/ui/elements/toast/danger-with-title';
import { ToastInfoWithTitle } from './src/common/ui/elements/toast/info-with-title';
import { ToastProvider } from 'react-native-toast-notifications';
import { ToastSuccessWithTitle } from './src/common/ui/elements/toast/success-with-title';

import en from './assets/localization/en.json';
import i18n from 'i18n-js';

i18n.translations = { en };
i18n.locale = Localization.locale;
i18n.fallbacks = true;

export default function App() {
    const [authState, setAuthState] = useState(AuthStateEnum.Guest);
    const [authUserInfo, setAuthUserInfo] = useState({});
    const [profile, setProfile] = useState(DefaultProfile);
    const [beneficiaryProfile, setBeneficiaryProfile] = useState(DefaultBeneficiaryProfile);

    const authProvider = new Auth0Provider({ setAuthState, setAuthUserInfo });
    let [fontsLoaded] = useFonts({
        DMSans_400Regular,
        DMSans_500Medium,
        DMSans_700Bold,
    });

    const resume = async () => {
        await authProvider.resumeSession();
    }
    useEffect(() => {
        resume();
    }, []);
    // useEffect(() => authProvider.resumeSession(), []);

    if (!fontsLoaded) return <Text>Loading...</Text>;
    return (
        <ThemeProvider theme={ReactNativeElementsTheme}>
            <StatusBar backgroundColor={'transparent'} translucent />
            <ToastProvider
                placement="top"
                duration={3500}
                offset={50}
                animationType="slide-in"
                successColor={theme.colors.successToast}
                successIcon={
                    <Icon
                        name="check-circle-outline"
                        color={theme.colors.shadWhite}
                    />
                }
                dangerColor={theme.colors.dangerToast}
                dangerIcon={
                    <Icon
                        name="close-circle-outline"
                        type="ionicon"
                        color={theme.colors.shadWhite}
                    />
                }
                infoColor={theme.colors.infoToast}
                infoIcon={
                    <Icon
                        name="alert-circle-outline"
                        type="ionicon"
                        color={theme.colors.shadWhite}
                    />
                }
                textStyle={{
                    letterSpacing: 0.15,
                    color: theme.colors.shadWhite,
                    flexWrap: 'wrap',
                    textAlign: 'justify',
                }}
                renderType={{
                    successWithTitle: (toast) => (
                        <ToastSuccessWithTitle {...toast} />
                    ),
                    dangerWithTitle: (toast) => (
                        <ToastDangerWithTitle {...toast} />
                    ),
                    infoWithTitle: (toast) => <ToastInfoWithTitle {...toast} />,
                }}
            >
                <SafeAreaProvider>
                    <MainContext.Provider
                        value={{
                            authState,
                            authUserInfo,
                            profile,
                            onError: (e) => Alert.alert(e),
                            setProfile,
                            beneficiaryProfile,
                            setBeneficiaryProfile,
                            refreshSession: async ({ shouldReload }) =>
                                await authProvider.refreshSession({
                                    shouldReload,
                                }),
                            signOut: () => authProvider.signOut(),
                            signIn: ({ email, password }) =>
                                authProvider.signIn({ email, password }),
                            signUp: ({ email, password }) =>
                                authProvider.signUp({ email, password }),
                            changePassword: ({ email }) => {
                                authProvider.forgotPassword({ email })
                            }
                        }}
                    >
                        <NavigationContainer theme={ReactNavigationTheme}>
                            <AuthenticationNavigator />
                            <OnboardingNavigator />
                            <FailedKYCNavigator />
                            <DrawerNavigator />
                        </NavigationContainer>
                    </MainContext.Provider>
                </SafeAreaProvider>
            </ToastProvider>
        </ThemeProvider>
    );
}
