import React, { useState } from 'react';
import { DatePickerNative } from './components/date-picker.component';
import { ScreenLayout } from '../../common/ui/components/screen-layout.component';
import { Text } from 'react-native-elements';
import { TransactionsList } from './components/transaction-list.component';
import { View } from 'react-native';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import moment from 'moment';
import { useRoute } from '@react-navigation/native';
import { WebDatePicker } from './components/web-date-picker.component';

const parseDate = (string) => moment(string, 'YYYY-MM-DD').toDate();

export const SearchTransactionsScreen = () => {
    const {
        params: { account, fromDate },
    } = useRoute();

    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(null);

    const parsedStartDate = startDate && parseDate(startDate);
    const parsedEndDate = endDate && parseDate(endDate);
    const today = moment().utc().toDate();

    return (
        <ScreenLayout>
            <TransactionsList
                accountId={account.id}
                fromDate={startDate}
                toDate={endDate}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: 25,
                        width: isWeb() ? 'fit-content' : null,
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                        }}
                    >
                        <View
                            style={{
                                width: isWeb() ? '45%' : '40%',
                                marginRight: 20,
                            }}
                        >
                            {isWeb() ?
                                <WebDatePicker
                                    label={i18n.t('pages.home.searchTransactions.startDate')}
                                    date={parsedStartDate}
                                    maximumDate={parsedEndDate}
                                    onChange={setStartDate}
                                /> :
                                <DatePickerNative
                                    label={i18n.t(
                                        'pages.home.searchTransactions.startDate'
                                    )}
                                    date={parsedStartDate}
                                    maximumDate={parsedEndDate}
                                    onChange={setStartDate}
                                />
                            }
                        </View>
                        <View
                            style={{
                                width: isWeb() ? '45%' : '40%',
                            }}
                        >
                            {isWeb() ?
                                <WebDatePicker
                                    label={i18n.t('pages.home.searchTransactions.endDate')}
                                    date={parsedEndDate}
                                    minimumDate={parsedStartDate}
                                    maximumDate={today}
                                    onChange={setEndDate}
                                /> :
                                <DatePickerNative
                                    label={i18n.t(
                                        'pages.home.searchTransactions.endDate'
                                    )}
                                    date={parsedEndDate}
                                    minimumDate={parsedStartDate}
                                    maximumDate={today}
                                    onChange={setEndDate}
                                />
                            }
                        </View>
                    </View>
                </View>
                <Text h2>
                    {i18n.t('pages.home.searchTransactions.subtitle')}
                </Text>
            </TransactionsList>
        </ScreenLayout>
    );
};
