import React from 'react';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import { useNavigation } from '@react-navigation/native';
import theme from '../../../common/ui/theme';

export const BackToLogin = () => {
    const navigation = useNavigation();
    return (
        <TouchableOpacity
            style={{
                marginTop: 25,
                outlineStyle: isWeb() ? 'none' : null,
            }}
            onPress={() => navigation.navigate('Auth.SignIn')}
        >
            <Text
                style={{
                    fontFamily: 'DMSans_400Regular',
                    color: theme.colors.phthaloBlue,
                    fontSize: 16,
                    lineHeight: 24,
                    textAlign: 'center',
                    letterSpacing: 0.15,
                    textDecorationLine: 'underline',
                }}
            >
                {i18n.t('common.backToLogin')}
            </Text>
        </TouchableOpacity>
    );
};
