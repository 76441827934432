import { Button, Text } from 'react-native-elements';
import { Image, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { CheckListItem } from '../../../../common/ui/elements/check-list-item.element';
import { LinearGradient } from 'expo-linear-gradient';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import { isWeb } from '../../../../common/utils/platform.utils';
import theme from '../../../../common/ui/theme';

export const CreditCardSelection = () => {
    const {
        params: { account },
    } = useRoute();

    return (
        <ModalLayout
            title={i18n.t('pages.home.manageCard.orderCard.title')}
            subtitle={i18n.t('pages.home.manageCard.orderCard.subtitle')}
        >
            <VirtualCardTile account={account} />
            <PhysicalCardTile account={account} />
        </ModalLayout>
    );
};

CreditCardSelection.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
};

const VirtualCardTile = ({ account }) => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() =>
                navigation.push('Home.OrderVirtualCard', { account })
            }
            activeOpacity={0.5}
        >
            <LinearGradient
                {...theme.gradients.lightBlue}
                style={{
                    justifyContent: 'center',
                    marginBottom: 32,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 10,
                    padding: 16,
                    background: '#FFFFFF',
                    // boxShadow: '0px 0px 0px 1px #E0E0E0',
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Image
                        source={require('../../../../../assets/images/deviceIcon.png')}
                        style={{ width: 22, height: 22, marginRight: 10 }}
                    />
                    <Text
                        style={{
                            fontFamily: 'DMSans_700Bold',
                            fontSize: 17,
                            color: 'rgba(0, 0, 0, 0.87)',
                            lineHeight: 20,
                        }}
                    >
                        {i18n.t(
                            'pages.home.manageCard.orderCard.cardType.virtual.title'
                        )}
                    </Text>
                </View>
                <Text
                    style={{
                        fontSize: 12,
                        fontFamily: 'DMSans_400Regular',
                        color: 'rgba(0, 0, 0, 0.87)',
                        lineHeight: 20,
                        letterSpacing: 0.4,
                        marginTop: 9,
                        marginBottom: 11,
                    }}
                >
                    {i18n.t(
                        'pages.home.manageCard.orderCard.cardType.virtual.description'
                    )}
                </Text>
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.virtual.item1'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.virtual.item2'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.virtual.item3'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
            </LinearGradient>
        </TouchableOpacity>
    );
};

VirtualCardTile.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    onPress: PropTypes.func,
};

const PhysicalCardTile = ({ account }) => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() =>
                navigation.push('Home.OrderPhysicalCard', { account })
            }
            activeOpacity={0.5}
            style={!isWeb() ? { ...theme.shadows.lightBlue } : null}
        >
            <LinearGradient
                {...theme.gradients.lightBlue}
                style={{
                    justifyContent: 'center',
                    marginBottom: 25,
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 10,
                    padding: 16,
                    background: '#FFFFFF',
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Image
                        source={require('../../../../../assets/images/creditcard.png')}
                        style={{ width: 22, height: 22, marginRight: 10 }}
                    />
                    <Text
                        style={{
                            fontFamily: 'DMSans_700Bold',
                            fontSize: 17,
                            color: 'rgba(0, 0, 0, 0.87)',
                            lineHeight: 20,
                        }}
                    >
                        {i18n.t(
                            'pages.home.manageCard.orderCard.cardType.physical.title'
                        )}
                    </Text>
                </View>
                <Text
                    style={{
                        fontSize: 12,
                        fontFamily: 'DMSans_400Regular',
                        color: 'rgba(0, 0, 0, 0.87)',
                        lineHeight: 20,
                        letterSpacing: 0.4,
                        marginTop: 9,
                        marginBottom: 11,
                    }}
                >
                    {i18n.t(
                        'pages.home.manageCard.orderCard.cardType.physical.description'
                    )}
                </Text>
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.physical.item1'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.physical.item2'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
                <CheckListItem
                    size={12}
                    label={i18n.t(
                        'pages.home.manageCard.orderCard.cardType.physical.item3'
                    )}
                    color="rgba(0,0,0,0.6)"
                />
            </LinearGradient>
        </TouchableOpacity>
    );
};

PhysicalCardTile.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    onPress: PropTypes.func,
};
