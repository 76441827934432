import { Text, View } from 'react-native';
import React from 'react';
import theme from '../../theme';

export const ToastDangerWithTitle = (toastProps) => (
    <View
        style={{
            maxWidth: '85%',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
            paddingHorizontal: 15,
            paddingVertical: 10,
            marginVertical: 4,
            backgroundColor: toastProps.dangerColor,
            borderColor: theme.colors.dangerToast,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 4,
        }}
    >
        <View style={{ marginRight: 5 }}>{toastProps.dangerIcon}</View>
        <View
            style={{
                width: '90%',
                maxWidth: '90%',
            }}
        >
            {toastProps.data && toastProps.data.title ? (
                <Text
                    style={{
                        ...toastProps.textStyle,
                        fontSize: 16,
                        fontFamily: 'DMSans_500Medium',
                        lineHeight: 24,
                        ...theme.typography.mediumFontWeight,
                    }}
                >
                    {toastProps.data.title}
                </Text>
            ) : (
                <></>
            )}
            <Text
                style={{
                    ...toastProps.textStyle,
                    fontSize: 14,
                    fontFamily: 'DMSans_400Regular',
                    lineHeight: 20,
                }}
            >
                {toastProps.message}
            </Text>
        </View>
    </View>
);
