import { Image, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import { isWeb } from '../common/utils/platform.utils';
import theme from '../common/ui/theme';
import { useNavigation } from '@react-navigation/native';

export const topScreenOptions = () => {
    const navigation = useNavigation();
    return {
        headerLeft: () => (
            <TouchableOpacity
                onPress={() => navigation.toggleDrawer()}
                style={{ paddingLeft: isWeb() ? 25 : 2 }}
            >
                <View
                    accessibility
                    accessibilityLabel={i18n.t(
                        'accessibility.navigation.navigationMenu'
                    )}
                    accessibilityRole="button"
                >
                    <Icon
                        name="menu"
                        size={24}
                        color={theme.colors.textHeading}
                    />
                </View>
            </TouchableOpacity>
        ),
        headerTitle: '',
        headerStyle: {
            backgroundColor: 'transparent',
        },
        headerShadowVisible: false,
    };
};

export const topScreenHeaderOptions = () => {
    const navigation = useNavigation();
    return {
        headerLeft: () => (
            <TouchableOpacity
                onPress={() => navigation.toggleDrawer()}
                style={{ paddingLeft: isWeb() ? 25 : 2 }}
            >
                <View
                    accessibility
                    accessibilityLabel={i18n.t(
                        'accessibility.navigation.navigationMenu'
                    )}
                    accessibilityRole="button"
                >
                    <Icon
                        name="menu"
                        size={24}
                        color={theme.colors.textHeading}
                    />
                </View>
            </TouchableOpacity>
        ),
        headerTitleStyle: {
            fontSize: isWeb() ? 22 : 24,
            fontFamily: 'DMSans_700Bold',
            lineHeight: 32,
            color: theme.colors.phthaloBlue,
            textAlign: 'center',
            maxWidth: 300,
        },
        headerStyle: {
            backgroundColor: 'transparent',
        },
        headerTitleAlign: 'center',
        headerShadowVisible: false,
    };
};

export const childScreenOptions = () => {
    return {
        headerShadowVisible: false,
        headerTitleStyle: {
            fontSize: isWeb() ? 22 : 24,
            fontFamily: 'DMSans_700Bold',
            lineHeight: 32,
            color: theme.colors.phthaloBlue,
            textAlign: 'center',
            maxWidth: 300,
        },
        headerStyle: {
            backgroundColor: 'transparent',
        },
        headerBackVisible: false,
        headerTitleAlign: 'center',
        headerLeft: ({ canGoBack }) => {
            const navigation = useNavigation();
            return canGoBack ? (
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <View
                        accessibility
                        accessibilityLabel={i18n.t(
                            'accessibility.navigation.back'
                        )}
                        accessibilityRole="button"
                    >
                        <Image
                            source={require('../../assets/images/back.png')}
                            style={{
                                width: 12,
                                height: 20,
                                marginLeft: isWeb() ? 25 : 2,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            ) : null;
        },
    };
};

export const CustomHeaderComponent = ({ maintitle, subtitle }) => {
    const navigation = useNavigation();

    return (
        <View style={{ width: '100%', paddingTop: !isWeb() ? 30 : 9, backgroundColor: 'transparent', flexDirection: 'row' }}>
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <View
                        accessibility
                        accessibilityLabel={i18n.t(
                            'accessibility.navigation.back'
                        )}
                        accessibilityRole="button"
                    >
                        <Image
                            source={require('../../assets/images/back.png')}
                            style={{
                                width: 12,
                                height: 20,
                                marginLeft: 20,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </View>
            <View style={{ backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                <Text
                    adjustsFontSizeToFit={true} allowFontScaling minimumFontScale={0.01} numberOfLines={1}
                    style={{
                        fontSize: isWeb() ? 22 : 24,
                        fontFamily: 'DMSans_700Bold',
                        lineHeight: 32,
                        color: theme.colors.phthaloBlue,
                        textAlign: 'center',
                        maxWidth: 300,
                        backgroundColor: 'transparent'
                    }}
                >
                    {maintitle}
                </Text>
                <Text style={{
                    fontSize: 16,
                    fontFamily: 'DMSans_500Medium',
                    lineHeight: 32,
                    color: theme.colors.phthaloBlue,
                    textAlign: 'center',
                    maxWidth: 300,
                }}>
                    {subtitle}
                </Text>
            </View>
        </View>
    );
}
