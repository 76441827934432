import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { MainContext } from '../../../App.context';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { BackToLogin } from './components/back-to-login.component';

export const ChangePassword = () => {
    return (
        <MainContext.Consumer>
            {({ changePassword }) => (
                <SafeAreaView style={{ flex: 1 }}>
                    <OnboardingPageLayout title={i18n.t('pages.forgotPassword.title')}>
                        <View style={{ marginTop: 50 }} />
                        <ChangePasswordForm onSubmit={changePassword} />
                        <BackToLogin />
                    </OnboardingPageLayout>
                </SafeAreaView>
            )}
        </MainContext.Consumer>
    );
};

const ChangePasswordForm = ({ onSubmit }) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: { email: '' },
    });

    return (
        <View>
            <Controller
                control={control}
                name="email"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(.+)@(.+)\.(.{2,6})$/,
                        message: i18n.t('forms.email.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            errors.email && errors.email.message
                        }
                        keyboardType="email-address"
                        label={i18n.t('forms.email.label')}
                        placeholder={i18n.t('forms.email.placeholder')}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        autoFocus={true}
                    />
                )}
            />

            <Button
                buttonStyle={theme.styles.buttonStylePrimary}
                titleStyle={theme.styles.titleStylePrimary}
                disabled={!isDirty || !isValid}
                title={i18n.t('common.submit')}
                onPress={handleSubmit(onSubmit)}
            />
        </View>
    );
};

ChangePasswordForm.propTypes = {
    onSubmit: PropTypes.func,
};
