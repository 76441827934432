import Constants from 'expo-constants';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import React from 'react';
import WebView from 'react-native-webview';
import i18n from 'i18n-js';
import { useRoute } from '@react-navigation/native';

export const PhysicalCardScreen = () => {
    const {
        params: { selectedCard },
    } = useRoute();

    return (
        <ModalLayout
            title={i18n.t('pages.home.manageCard.physicalCardDetails.title')}
            subtitle={i18n.t(
                'pages.home.manageCard.physicalCardDetails.description'
            )}
        >
            <WebView
                originWhitelist={['*']}
                containerStyle={{
                    position: 'absolute',
                    left: 30.5,
                    top: 200,
                    margin: 0,
                    width: 900,
                    flex: 1,
                    height: 400,
                }}
                source={{
                    uri: `${Constants.manifest.extra.apiBaseURI}/card/${selectedCard.id}/details`,
                }}
            />
        </ModalLayout>
    );
};
