import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import theme from '../theme';

export const LabelIcon = ({ label, onPress, children }) => {
    return (
        <TouchableOpacity
            disabled={!onPress}
            onPress={() => onPress && onPress()}
            style={{
                opacity: onPress ? 1 : 0.5,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            {children}
            <Text
                style={{
                    textAlign: 'center',
                    marginTop: 11,
                    fontSize: 14,
                    fontFamily: 'DMSans_500Medium',
                    lineHeight: 22,
                    letterSpacing: 0.1,
                    color: theme.colors.fontBackground,
                    opacity: onPress ? 0.87 : 0.38,
                }}
            >
                {label}
            </Text>
        </TouchableOpacity>
    );
};

LabelIcon.propTypes = {
    label: PropTypes.string,
    onPress: PropTypes.func,
    children: PropTypes.any,
};
