import { View, TouchableOpacity, Pressable, Modal, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-elements';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { isIos } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';

export const CustomAlert = ({ title, message, modalVisible, setModalVisible }) => {

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(false);
            }}
        >
            <Pressable style={[isIos ? theme.styles.iosBackdrop : theme.styles.androidBackdrop, theme.styles.backdrop]} onPress={() => setModalVisible(false)} />
            <View style={styles.alertBox}>
                <View style={[styles.innerAlertBox]}>
                    <Text style={[styles.modalTitle]}>{title}</Text>
                    <Text style={[styles.modalMessage]}>{message}</Text>
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        title={i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.yesCancelTransfer')}
                        onPress={() => {
                            setModalVisible(false)
                        }}
                    />
                    <TouchableOpacity style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onPress={() => setModalVisible(false)}>
                        <Text style={theme.styles.navigationButton}>
                            {i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.notNow')}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    alertBox: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 16
    },
    innerAlertBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 24,
        borderRadius: 20,
        backgroundColor: theme.colors.shadWhite,
    },
    modalTitle: {
        fontFamily: 'DMSans_700Bold',
        fontSize: 24,
        lineHeight: 32,
        textAlign: 'center',
        color: theme.colors.phthaloBlue,
        marginBottom: 16
    },
    modalMessage: {
        fontFamily: 'DMSans_400Regular',
        fontSize: 16,
        lineHeight: 19,
        textAlign: 'center',
        marginBottom: 16
    }
});

CustomAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    modalVisible: PropTypes.bool,
    setModalVisible: PropTypes.func
};