import { FlatList, Image, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import React, { useRef, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AccountTile } from '../accounts/components/account-tile.component';
import { LabelIcon } from '../../common/ui/elements/label-icon.element';
import { Pill } from '../../common/ui/elements/pill.element';
import { ScreenLayout } from '../../common/ui/components/screen-layout.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { TransactionsList } from './components/transaction-list.component';
import i18n from 'i18n-js';
import moment from 'moment';
import theme from '../../common/ui/theme';
import { MoveMoneyAccountSummary } from './move-money.modal';

export const AccountSummaryScreen = () => {
    const navigation = useNavigation();
    const {
        params: { accounts, account },
    } = useRoute();

    const filterListRef = useRef(null);
    const [days, setDays] = useState(7);

    const [modalVisible, setModalVisible] = useState(false);
    const parseFromDate = (days) =>
        moment()
            .utc()
            .startOf('day')
            .subtract(days, 'days')
            .format('YYYY-MM-DD');

    const loading = () => {
        setTimeout(function () {
            setModalVisible(true);
        }, 1000);
    }

    return (
        <ScreenLayout>
            <TransactionsList
                accountId={account.id}
                fromDate={parseFromDate(days)}
                showPending={true}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginBottom: 25,
                    }}
                >
                    <View style={{ width: '100%' }}>
                        <AccountTile account={account} />
                    </View>
                    <MoveMoneyAccountSummary
                        accounts={accounts}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        title={i18n.t('pages.home.accountSummary.movemoney.title')}
                    />
                    <View
                        style={{
                            marginTop: 50,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            width: '100%',
                            marginBottom: 20,
                            paddingHorizontal: 10,
                        }}
                    >
                        <LabelIcon
                            label={i18n.t(
                                'pages.home.accountSummary.actions.fund'
                            )}
                            onPress={() => loading()}
                        >
                            <Image
                                source={require('../../../assets/images/exchange.png')}
                                style={{ width: 24, height: 24 }}
                            />
                        </LabelIcon>
                        <LabelIcon
                            label={i18n.t(
                                'pages.home.accountSummary.actions.statements'
                            )}
                        >
                            <Image
                                source={require('../../../assets/images/calender.png')}
                                style={{ width: 24, height: 24 }}
                            />
                        </LabelIcon>
                        <LabelIcon
                            label={i18n.t(
                                'pages.home.accountSummary.actions.manageCard'
                            )}
                            onPress={() =>
                                navigation.navigate('Home.ManageCard', {
                                    account,
                                })
                            }
                        >
                            <Image
                                source={require('../../../assets/images/creditcard.png')}
                                style={{ width: 24, height: 24 }}
                            />
                        </LabelIcon>
                        <LabelIcon
                            label={i18n.t(
                                'pages.home.accountSummary.actions.details'
                            )}
                        >
                            <Image
                                source={require('../../../assets/images/details.png')}
                                style={{ width: 24, height: 24 }}
                            />
                        </LabelIcon>
                    </View>
                </View>
                <View
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}
                >
                    <Text h2>{i18n.t('pages.home.transactions.title')}</Text>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate('Home.SearchTransactions', {
                                account,
                                fromDate: parseFromDate(days),
                            });
                        }}
                    >
                        <View
                            accessibility
                            accessibilityLabel={i18n.t(
                                'pages.home.accountSummary.accessibility.search'
                            )}
                            accessibilityRole="button"
                        >
                            <Icon
                                name="search"
                                color={theme.colors.phthaloBlue}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
                <FlatList
                    horizontal={true}
                    onScrollToIndexFailed={(info) => {
                        const wait = new Promise((resolve) =>
                            setTimeout(resolve, 700)
                        );
                        wait.then(() => {
                            filterListRef.current?.scrollToIndex({
                                index: info.index,
                                animated: true / false,
                            });
                        });
                    }}
                    showsHorizontalScrollIndicator={false}
                    ref={filterListRef}
                    keyExtractor={(item) => item.value}
                    extraData={days}
                    data={[
                        {
                            label: i18n.t('pages.home.transactions.7days'),
                            value: 7,
                        },
                        {
                            label: i18n.t('pages.home.transactions.30days'),
                            value: 30,
                        },
                        {
                            label: i18n.t('pages.home.transactions.60days'),
                            value: 60,
                        },
                        {
                            label: i18n.t('pages.home.transactions.90days'),
                            value: 90,
                        },
                    ]}
                    renderItem={({ item, index }) => (
                        <Pill
                            label={item.label}
                            active={days === item.value}
                            onPress={() => {
                                setDays(item.value);
                                filterListRef.current.scrollToIndex({
                                    animated: true,
                                    index,
                                });
                            }}
                        />
                    )}
                />
            </TransactionsList>
        </ScreenLayout>
    );
};
