import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { CheckListItem } from '../../common/ui/elements/check-list-item.element';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import { PlaidLink } from '../accounts/components/plaid-link.component';
import React from 'react';
import { View } from 'react-native';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';

export const MakeFirstDepositModal = () => {
    const navigation = useNavigation();
    const {
        params: { account },
    } = useRoute();
    return (
        <ModalLayout small>
            <View
                style={{
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1 }}>
                    <Text
                        style={{
                            fontSize: 17,
                            marginBottom: 20,
                            color: theme.colors.textHeading,
                            ...theme.typography.mediumFontWeight,
                        }}
                    >
                        {i18n.t('pages.home.news.makeDeposit')}
                    </Text>
                    <Text
                        style={{
                            fontSize: 14,
                            marginBottom: 10,
                            ...theme.typography.mediumFontWeight,
                        }}
                    >
                        {i18n.t('pages.home.news.description')}
                    </Text>

                    <CheckListItem
                        label={i18n.t('pages.home.news.linkAccount')}
                        size={14}
                    />
                    <CheckListItem
                        label={i18n.t('pages.home.news.completeDeposit')}
                        size={14}
                    />
                    <CheckListItem
                        label={i18n.t('pages.home.news.willTake2min')}
                        size={14}
                    />
                </View>
            </View>
            <PlaidLink
                accountId={account.id}
                onSuccess={() => navigation.goBack()}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('pages.home.makeDepositModal.confirm')}
                    disabled
                    disabledStyle={
                        theme.ReactNativeElementsTheme.Button.buttonStyle
                    }
                    disabledTitleStyle={
                        theme.ReactNativeElementsTheme.Button.titleStyle
                    }
                ></Button>
            </PlaidLink>
        </ModalLayout>
    );
};
