import { View, TouchableOpacity, Pressable, Modal, StyleSheet, Image } from 'react-native';
import { Text } from 'react-native-elements';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18n-js';
import { useNavigation } from '@react-navigation/native';
import { isIos } from '../../common/utils/platform.utils';
import theme from '../../common/ui/theme';

export const MoveMoneyAccountSummary = ({ title, modalVisible, setModalVisible, accounts }) => {
    const navigation = useNavigation();
    const selectedExternalAccounts = [];
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(false);
            }}
        >
            <Pressable style={[isIos ? theme.styles.iosBackdrop : theme.styles.androidBackdrop, theme.styles.backdrop]} onPress={() => setModalVisible(false)} />
            <View style={styles.alertBox}>
                <View style={[styles.innerAlertBox]}>
                    <Text style={[styles.modalTitle]}>{title}</Text>
                    <ActionItem
                        title={i18n.t(
                            'pages.home.accountSummary.movemoney.transferAccounts.title'
                        )}
                        description={i18n.t(
                            'pages.home.accountSummary.movemoney.transferAccounts.description'
                        )}
                        iconName={require('../../../assets/images/swapHorizontalCircle.png')}
                        onClick={() => {
                            setModalVisible(false);
                            navigation.push('MoveMoney.AccountDetails', { accounts })
                        }
                        }
                    />
                    <ActionItem
                        title={i18n.t(
                            'pages.home.accountSummary.movemoney.withdrawFund.title'
                        )}
                        description={i18n.t(
                            'pages.home.accountSummary.movemoney.withdrawFund.description'
                        )}
                        iconName={require('../../../assets/images/outbound.png')}
                        onClick={() => {
                            setModalVisible(false)
                            navigation.push('MoveMoney.WithDrawFundDetail', { accounts, selectedExternalAccounts })
                        }}
                    />
                    <ActionItem
                        title={i18n.t(
                            'pages.home.accountSummary.movemoney.fundMyAccount.title'
                        )}
                        description={i18n.t(
                            'pages.home.accountSummary.movemoney.fundMyAccount.description'
                        )}
                        iconName={require('../../../assets/images/input.png')}
                        onClick={() => {
                            setModalVisible(false)
                            navigation.push('MoveMoney.FundMyAccountDetail', { accounts, selectedExternalAccounts })
                        }
                        }
                    />

                </View>
            </View>
        </Modal>
    )
}

MoveMoneyAccountSummary.propTypes = {
    title: PropTypes.string,
    modalVisible: PropTypes.bool,
    setModalVisible: PropTypes.func,
    accounts: PropTypes.array
};

const ActionItem = ({
    title,
    description,
    iconName,
    onClick,
}) => {
    return (
        <View style={theme.styles.mediumBlueBorder}>
            <TouchableOpacity
                onPress={() => onClick()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                            <Image
                                source={iconName}
                                style={{ width: 40, height: 40, marginRight: 20 }}
                            />
                            <View
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 17,
                                        lineHeight: 20,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        ...theme.typography.boldFontWeight,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8,
                                    }}
                                >
                                    {title}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 12,
                                        lineHeight: 20,
                                        ...theme.typography.regularFontWeight,
                                        letterSpacing: 0.4,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                >
                                    {description}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};

ActionItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    iconName: PropTypes.any,
};

const styles = StyleSheet.create({
    alertBox: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    innerAlertBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 24,
        backgroundColor: theme.colors.shadWhite,
    },
    modalTitle: {
        display: 'flex',
        alignSelf: 'flex-start',
        fontFamily: 'DMSans_700Bold',
        fontSize: 16,
        lineHeight: 28,
        letterSpacing: 0.15,
        color: theme.colors.phthaloBlue,
        marginBottom: 8
    },
});
