import { AccountBalancesScreen } from './balances.screen';
import PropTypes from 'prop-types';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { topScreenOptions } from '../../navigation/screen-options.component';

const Stack = createNativeStackNavigator();

export const AccountsStackNavigator = () => (
    <Stack.Navigator screenOptions={{ ...topScreenOptions() }}>
        <Stack.Screen
            name="Accounts.Balances"
            component={AccountBalancesScreen}
        />
    </Stack.Navigator>
);

AccountsStackNavigator.propTypes = {
    navigation: PropTypes.object.isRequired,
};
