import Constants from 'expo-constants';
import { PlaidLinkNative } from './plaid-link-native.component';
import PropTypes from 'prop-types';
import React from 'react';

export const PlaidLink = ({
    accountId,
    onReady,
    onLinking,
    onSuccess,
    children,
}) => {
    return Constants.appOwnership === 'expo' ? (
        children
    ) : (
        <PlaidLinkNative
            accountId={accountId}
            onReady={onReady}
            onLinking={onLinking}
            onSuccess={(externalAccounts) => onSuccess(externalAccounts)}
        >
            {children}
        </PlaidLinkNative>
    );
};
PlaidLink.propTypes = {
    accountId: PropTypes.string,
    onReady: PropTypes.func,
    onSuccess: PropTypes.func,
    onLinking: PropTypes.func,
    children: PropTypes.any,
};
