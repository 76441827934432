import React, { useRef, useState } from 'react';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { Input } from 'react-native-elements';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import i18n from 'i18n-js';
import moment from 'moment';

const formatDate = (date, dateFormat) => dateFormat ? moment(date).format(dateFormat) :
    moment(date).format('YYYY-MM-DD');

export const DatePickerNative = ({
    label,
    date,
    minimumDate,
    maximumDate,
    onChange,
    dateFormat
}) => {
    const textFieldRef = useRef(null);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        onChange(formatDate(date));
        hideDatePicker();
    };

    const initialDate = date || moment().toDate();
    const formattedDate = date && dateFormat ? formatDate(date, dateFormat) : date && formatDate(date);

    return (
        <View
            style={{
                flexDirection: 'column',
            }}
        >
            <Input
                label={label}
                ref={textFieldRef}
                placeholder={i18n.t('common.selectDate')}
                onFocus={() => {
                    showDatePicker();
                    textFieldRef.current.blur();
                }}
                value={formattedDate}
                rightIcon={{
                    name: 'keyboard-arrow-down',
                    onPress: () => showDatePicker(),
                }}
            />
            <DateTimePickerModal
                isVisible={isDatePickerVisible}
                mode="date"
                date={initialDate}
                minimumDate={minimumDate}
                maximumDate={maximumDate}
                onConfirm={handleConfirm}
                onCancel={hideDatePicker}
            />
        </View>
    );
};

DatePickerNative.propTypes = {
    label: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    minimumDate: PropTypes.instanceOf(Date),
    maximumDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
};
