import { Alert, View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../common/ui/components/modal-layout.component';
import React from 'react';
import { api } from '../../../App.context';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useWindowDimensions } from 'react-native';
import { MaskedText } from 'react-native-mask-text';
import { TextGradient } from '../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../common/ui/elements/text-currency.element';

export const ReadyTransferModal = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const toast = useToast();

    const {
        params: { data },
    } = useRoute();
    const accountFromBalance = data?.fromAccount?.balances?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );
    const accountToBalance = data?.toAccount?.balances?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );

    const navigateToTransfer = (response) => {
        navigation.push('MoveMoney.FundTransferred', { response, data })
        toast.show(i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.transferSuccessDescription'), {
            type: 'successWithTitle',
        });
    };

    const moneyTransfer = (formData) => {
        console.log('formData', formData);
        const amountInCents = formData.amount * 100;
        navigation.push('MoveMoney.FundTransferred', { data })
        /*  New API will be needed for the fund transfer */
        /*  api.post('/transfer_funds', {
             body: {
                 amount: amountInCents,
                 transferType: 'debit',
                 fromAccount: `${formData.fromAccount.id}`,
                 toAccount: `${formData.toAccount.id}`,
                 when: formData.when != null ? `${moment(formData.when).format('YYYY-MM-DD')}` : '',
                 howOften: formData.howOften,
                 endOn: formData.endOn,
                 endOn_NumTransfer: formData.endOn_NumTransfer
             },
         })
             .then((response) => {
                 navigateToTransfer(response);
             })
             .catch((err) => {
                 isWeb()
                     ? toast.show(err.message, {
                         type: 'dangerWithTitle',
                         data: {
                             title: i18n.t('common.errorTitle'),
                         },
                     })
                     : Alert.alert(i18n.t('common.errorTitle'), err.message);
             }); */
    };

    return (
        <ModalLayout>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.from')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.fromAccount?.account_type} {' '}
                        <MaskedText mask="9999 9999 999 ****">
                            {data?.fromAccount?.account_number}
                        </MaskedText>
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountFromBalance &&
                                accountFromBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.to')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.toAccount?.account_type} {' '}
                        <MaskedText mask="9999 9999 999 ****">
                            {data?.toAccount?.account_number}
                        </MaskedText>
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountToBalance &&
                                accountToBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.amount')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {'$' + data?.amount + '.00'}
                    </Text>
                </View>
                {data?.when && (
                    <View
                        style={{
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <Text style={theme.styles.textBold}>
                            {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.when')}
                        </Text>
                        <Text style={theme.styles.textRegular}>
                            {moment(data?.when).format('MMMM DD, YYYY')}
                        </Text>
                    </View>
                )}
                {!!data?.howOften && (
                    <>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.repeat')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {data?.howOften}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.form.endon')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {data?.endOn == "NumTransfer" ? data?.endOn_NumTransfer : data?.endOn == "Date" ? moment(data?.endOn_NumTransfer).format('MMMM DD, YYYY') : 'None'}
                            </Text>
                        </View>
                    </>
                )}
                <View
                    style={{
                        bottom: 20,
                        position: isWeb() ? 'fixed' : 'absolute',
                        width: width - 50,
                    }}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        title={i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.transfer')}
                        onPress={() => moneyTransfer(data)}
                    />
                </View>
            </View>
        </ModalLayout>
    );
};
