import { MoneyMovementListScreen } from './money-movement-list.screen';
import { AccountsMoneyTransfer } from './accounts-money-transfer.screen';
import { ReadyTransferModal } from './ready-transfer.screen';
import { TransferredFund } from './transfered-fund.screen';
import { WireTransferDetail } from './wire-transfer/wire-transfer-details.screen';
import { BeneficiaryInformation } from './wire-transfer/beneficiary-information.screen';
import PropTypes from 'prop-types';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { childScreenOptions, topScreenHeaderOptions, topScreenOptions } from '../../navigation/screen-options.component';
import i18n from 'i18n-js';
import { EditBeneficiaryInfo } from './wire-transfer/edit-beneficiary-info.screen';
import { WireReadyTransferModal } from './wire-transfer/wire-ready-transfer.screen';
import { WireCompleteTransfer } from './wire-transfer/wire-complete-transfer.screen';
import { WithDrawFundDetail } from './send-money-out/withdraw-fund-details.screen';
import { ExternalAccountListScreen } from './send-money-out/external-account-list.screen';
import { LinkExternalAccount } from './send-money-out/link-external-account.screen';
import { WithDrawReadyTransferModal } from './send-money-out/withdraw-ready-transfer.screen';
import { WithDrawTransferredFund } from './send-money-out/withdraw-transfered-fund.screen';
import { ExternalCardListScreen } from './send-money-out/external-card-list.screen';
import { LinkExternalCard } from './send-money-out/link-external-card.screen';
import { FundMyAccountDetail } from './fund-my-account/fund-my-account-details.screen';
import { ManageTransferListScreen } from './manage-upcoming/transfer-list.screen';
import { ManageTransferDetail } from './manage-upcoming/manage-upcoming-transfer-details';
import { HomeScreen } from '../home/home.screen';
import { ReviewDepositModal } from '../home/review-deposit.modal';

const Stack = createNativeStackNavigator();

export const MoveMoneyStackNavigator = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen
                name="MoveMoney.DetailsList"
                component={MoneyMovementListScreen}
                options={{
                    ...topScreenHeaderOptions(),
                    title: i18n.t('pages.moveMoney.screenTitle'),
                }}
            />
            <Stack.Screen
                name="MoveMoney.AccountDetails"
                options={{
                    headerShown: false
                }}
                component={AccountsMoneyTransfer}
            />
            <Stack.Screen
                name="MoveMoney.ReadyTransfer"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.transferTitle'),
                }}
                component={ReadyTransferModal}
            />
            <Stack.Screen
                name="MoveMoney.FundTransferred"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.transferAccounts.fundtransfer.transferredTitle'),
                }}
                component={TransferredFund}
            />
            <Stack.Screen
                name="MoveMoney.WireTransferDetail"
                options={{
                    headerShown: false
                }}
                component={WireTransferDetail}
            />
            <Stack.Screen
                name="MoveMoney.beneficiaryInformationDetail"
                options={{
                    headerShown: false
                }}
                component={BeneficiaryInformation}
            />
            <Stack.Screen
                name="MoveMoney.editBeneficiaryInfo"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.title')
                }}
                component={EditBeneficiaryInfo}
            />
            <Stack.Screen
                name="MoveMoney.WireReadyTransfer"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.transferTitle')
                }}
                component={WireReadyTransferModal}
            />
            <Stack.Screen
                name="MoveMoney.WireCompleteTransfer"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.wireTransfer.completeTransfer.title')
                }}
                component={WireCompleteTransfer}
            />
            <Stack.Screen
                name="MoveMoney.WithDrawFundDetail"
                options={{
                    headerShown: false
                }}
                component={WithDrawFundDetail}
            />
            <Stack.Screen
                name="MoveMoney.externalAccountList"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.exteranalAccountList.title')
                }}
                component={ExternalAccountListScreen}
            />
            <Stack.Screen
                name="MoveMoney.linkExternalAccount"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.exteranalAccountList.title')
                }}
                component={LinkExternalAccount}
            />
            <Stack.Screen
                name="MoveMoney.WithDrawFundReadyTransfer"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.readyToTransfer.transferTitle')
                }}
                component={WithDrawReadyTransferModal}
            />
            <Stack.Screen
                name="MoveMoney.WithDrawFundTransferred"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.fundtransfer.transferredTitle'),
                }}
                component={WithDrawTransferredFund}
            />
            <Stack.Screen
                name="MoveMoney.ExternalCardList"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.title')
                }}
                component={ExternalCardListScreen}
            />
            <Stack.Screen
                name="MoveMoney.linkExternalCard"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.title')
                }}
                component={LinkExternalCard}
            />
            <Stack.Screen
                name="MoveMoney.FundMyAccountDetail"
                options={{
                    headerShown: false
                }}
                component={FundMyAccountDetail}
            />
            <Stack.Screen
                name="MoveMoney.ManageTransferList"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.manageTransfer.manageUpcomingList.title')
                }}
                component={ManageTransferListScreen}
            />
            <Stack.Screen
                name="MoveMoney.ManageTransferDetail"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.title')
                }}
                component={ManageTransferDetail}
            />
            <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={{
                    ...topScreenOptions(),
                }}
            />
            <Stack.Screen
                name="Home.ReviewDeposit"
                options={{
                    ...childScreenOptions(),
                    title: i18n.t('pages.home.makeDeposit.reviewTitle'),
                }}
                component={ReviewDepositModal}
            />
        </Stack.Navigator>
    );
};

MoveMoneyStackNavigator.propTypes = {
    navigation: PropTypes.object.isRequired,
};
