import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native';

export const TextGradient = ({ children, style }) => {
    return <Text style={style}>{children}</Text>;
};

TextGradient.propTypes = {
    children: PropTypes.any,
    style: PropTypes.any,
};
