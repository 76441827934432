import {
    childScreenOptions,
    topScreenHeaderOptions,
} from '../../navigation/screen-options.component';
import { EditAddressesScreen } from './edit-addresses.screen';
import { EditEmailScreen } from './edit-email.screen';
import { EditPhoneScreen } from './edit-phone.screen';
import { ProfileScreen } from './profile.screen';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from 'i18n-js';

const Stack = createNativeStackNavigator();

export const ProfileStackNavigator = () => (
    <Stack.Navigator initialRouteName="Profile.ViewProfile">
        <Stack.Screen
            name="Profile.ViewProfile"
            options={{
                ...topScreenHeaderOptions(),
                title: i18n.t('pages.myProfile.drawerTitle'),
            }}
            component={ProfileScreen}
        />
        <Stack.Screen
            name="Profile.EditAddresses"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.profile.addresses'),
            }}
            component={EditAddressesScreen}
        />
        <Stack.Screen
            name="Profile.EditPhone"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.profile.phoneNumber'),
            }}
            component={EditPhoneScreen}
        />
        <Stack.Screen
            name="Profile.EditEmail"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.profile.email'),
            }}
            component={EditEmailScreen}
        />
    </Stack.Navigator>
);

ProfileStackNavigator.propTypes = {};
