import * as ExpoSecureStore from 'expo-secure-store';
import { isWeb } from './platform.utils';

export async function setItemAsync(key, value) {
    isWeb()
        ? localStorage.setItem(key, value)
        : await ExpoSecureStore.setItemAsync(key, value);
}

export async function getItemAsync(key) {
    return isWeb()
        ? localStorage.getItem(key)
        : await ExpoSecureStore.getItemAsync(key);
}

export async function clearItemAsync(key) {
    return isWeb()
        ? localStorage.removeItem(key)
        : await ExpoSecureStore.deleteItemAsync(key);
}

export const webViewDesignSupport = isWeb() ? { outlineStyle: 'none' } : null;
