import { View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import React from 'react';
import { MainContext } from '../../../../App.context';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import theme from '../../../common/ui/theme';
import { useWindowDimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaskedText } from 'react-native-mask-text';

export const WireCompleteTransfer = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const {
        params: { beneficiaryData, data },
    } = useRoute();

    return (
        <MainContext.Consumer>
            {({ beneficiaryProfile }) => (
                <ModalLayout>
                    <View style={{ flex: 1 }}>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.amount')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {'$' + data?.amount + '.00'}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.from')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {data?.your_account?.account_type} {' '}
                                <MaskedText mask="**** **** **** 9999">
                                    {data?.your_account?.account_number}
                                </MaskedText>
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.to')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryProfile?.beneficiaryName}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryProfile?.accountNumber}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryProfile?.bank_address.street_address_1}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryProfile?.bank_address.city}{','} {beneficiaryProfile?.bank_address.state}{','} {beneficiaryProfile?.bank_address.postal_code}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryProfile?.bank_address.country_code}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                            <Text style={theme.styles.textBold}>
                                {i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.form.purpose')}
                            </Text>
                            <Text style={theme.styles.textRegular}>
                                {beneficiaryData.state_of_purpose}
                            </Text>
                        </View>
                        <View
                            style={{
                                bottom: 20,
                                position: isWeb() ? 'fixed' : 'absolute',
                                width: width - 50,
                            }}
                        >
                            <Button
                                buttonStyle={theme.styles.buttonStylePrimary}
                                titleStyle={theme.styles.titleStylePrimary}
                                loadingProps={{
                                    color: theme.colors.textHeading,
                                }}
                                title={i18n.t('common.backToAccount')}
                                onPress={() => navigation.push('Home')}
                            />
                            <TouchableOpacity onPress={() => navigation.push('Home')}>
                                <Text style={theme.styles.navigationButton}>
                                    {i18n.t('common.anotherTransfer')}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ModalLayout>
            )}
        </MainContext.Consumer>
    );
};
