import {
    childScreenOptions,
    topScreenOptions,
} from '../../navigation/screen-options.component';
import { AccountSummaryScreen } from './account-summary.screen';
import { ActivateCard } from '../accounts/components/cards/activate-card.screen';
import { CreditCardSelection } from '../accounts/components/cards/credit-card-selection.screen';
import { EditAddressesScreen } from '../profile/edit-addresses.screen';
import { HomeScreen } from './home.screen';
import { MakeDepositModal } from './make-deposit.modal';
import { MakeFirstDepositModal } from './make-first-deposit.modal';
import { ManageCardScreen } from './manage-card.screen';
import { LineOfCreditAccount } from '../accounts/components/line-of-credit-account';
import { OpenAccount } from '../accounts/components/open-account.screen';
import { OrderPhysicalCard } from '../accounts/components/cards/order-physical-card.screen';
import { OrderVirtualCard } from '../accounts/components/cards/order-virtual-card.screen';
import { PhysicalCardOrdered } from '../accounts/components/cards/physical-card-ordered.screen';
import { PhysicalCardScreen } from '../accounts/components/cards/physical-card-screen';
import PropTypes from 'prop-types';
import React from 'react';
import { ReviewDepositModal } from './review-deposit.modal';
import { SearchTransactionsScreen } from './search-transactions.screen';
import { VerifyIdentityModal } from '../kyc/verify-identity.modal';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { AccountsMoneyTransfer } from '../move-money/accounts-money-transfer.screen';
import { ReadyTransferModal } from '../move-money/ready-transfer.screen';
import { TransferredFund } from '../move-money/transfered-fund.screen';
import { WireTransferDetail } from '../move-money/wire-transfer/wire-transfer-details.screen';
import { BeneficiaryInformation } from '../move-money/wire-transfer/beneficiary-information.screen';
import { EditBeneficiaryInfo } from '../move-money/wire-transfer/edit-beneficiary-info.screen';
import { WireReadyTransferModal } from '../move-money/wire-transfer/wire-ready-transfer.screen';
import { WireCompleteTransfer } from '../move-money/wire-transfer/wire-complete-transfer.screen';
import { WithDrawFundDetail } from '../move-money/send-money-out/withdraw-fund-details.screen';
import { ExternalAccountListScreen } from '../move-money/send-money-out/external-account-list.screen';
import { LinkExternalAccount } from '../move-money/send-money-out/link-external-account.screen';
import { WithDrawReadyTransferModal } from '../move-money/send-money-out/withdraw-ready-transfer.screen';
import { WithDrawTransferredFund } from '../move-money/send-money-out/withdraw-transfered-fund.screen';
import { ExternalCardListScreen } from '../move-money/send-money-out/external-card-list.screen';
import { LinkExternalCard } from '../move-money/send-money-out/link-external-card.screen';
import { FundMyAccountDetail } from '../move-money/fund-my-account/fund-my-account-details.screen';
import { ManageTransferListScreen } from '../move-money/manage-upcoming/transfer-list.screen';
import { ManageTransferDetail } from '../move-money/manage-upcoming/manage-upcoming-transfer-details';

const Stack = createNativeStackNavigator();

export const HomeStackNavigator = () => (
    <Stack.Navigator>
        <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{
                ...topScreenOptions(),
            }}
        />

        <Stack.Screen
            name="Home.OpenAccount"
            component={OpenAccount}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.accountsTitle'),
            }}
        />

        <Stack.Screen
            name="Home.AccountSummary"
            component={AccountSummaryScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.accountSummary.title'),
            }}
        />
        <Stack.Screen
            name="Home.SearchTransactions"
            component={SearchTransactionsScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.searchTransactions.title'),
            }}
        />
        <Stack.Screen
            name="Home.ManageCard"
            component={ManageCardScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.MakeFirstDeposit"
            options={{
                presentation: 'transparentModal',
                headerShown: false,
                animation: 'fade',
            }}
            component={MakeFirstDepositModal}
        />

        <Stack.Screen
            name="Home.MakeDeposit"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.makeDeposit.title'),
            }}
            component={MakeDepositModal}
        />

        <Stack.Screen
            name="Home.ReviewDeposit"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.makeDeposit.reviewTitle'),
            }}
            component={ReviewDepositModal}
        />

        <Stack.Screen
            name="Home.CreditCardSelection"
            component={CreditCardSelection}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.OrderVirtualCard"
            component={OrderVirtualCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.OrderPhysicalCard"
            component={OrderPhysicalCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.PhysicalCardOrdered"
            component={PhysicalCardOrdered}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.PhysicalCardDetails"
            component={PhysicalCardScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.ActivateCard"
            component={ActivateCard}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.home.manageCard.title'),
            }}
        />

        <Stack.Screen
            name="Home.EditAddress"
            component={EditAddressesScreen}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.profile.addresses'),
            }}
        />

        <Stack.Screen
            name="Home.VerifyIdentity"
            component={VerifyIdentityModal}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.kycInReview.verifyIdentity'),
            }}
        />

        <Stack.Screen
            name="Home.LineOfCreditAccount"
            component={LineOfCreditAccount}
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.accountSelection.lineofCreditAccount.headerTitle'),
            }}
        />

        <Stack.Screen
            name="MoveMoney.AccountDetails"
            options={{
                headerShown: false
            }}
            component={AccountsMoneyTransfer}
        />
        <Stack.Screen
            name="MoveMoney.ReadyTransfer"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.transferAccounts.readyToTransfer.transferTitle'),
            }}
            component={ReadyTransferModal}
        />
        <Stack.Screen
            name="MoveMoney.FundTransferred"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.transferAccounts.fundtransfer.transferredTitle'),
            }}
            component={TransferredFund}
        />
        <Stack.Screen
            name="MoveMoney.WireTransferDetail"
            options={{
                headerShown: false
            }}
            component={WireTransferDetail}
        />
        <Stack.Screen
            name="MoveMoney.beneficiaryInformationDetail"
            options={{
                headerShown: false
            }}
            component={BeneficiaryInformation}
        />
        <Stack.Screen
            name="MoveMoney.editBeneficiaryInfo"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.title')
            }}
            component={EditBeneficiaryInfo}
        />
        <Stack.Screen
            name="MoveMoney.WireReadyTransfer"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.wireTransfer.readyToTransfer.transferTitle')
            }}
            component={WireReadyTransferModal}
        />
        <Stack.Screen
            name="MoveMoney.WireCompleteTransfer"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.wireTransfer.completeTransfer.title')
            }}
            component={WireCompleteTransfer}
        />
        <Stack.Screen
            name="MoveMoney.WithDrawFundDetail"
            options={{
                headerShown: false
            }}
            component={WithDrawFundDetail}
        />
        <Stack.Screen
            name="MoveMoney.externalAccountList"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.exteranalAccountList.title')
            }}
            component={ExternalAccountListScreen}
        />
        <Stack.Screen
            name="MoveMoney.linkExternalAccount"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.exteranalAccountList.title')
            }}
            component={LinkExternalAccount}
        />
        <Stack.Screen
            name="MoveMoney.WithDrawFundReadyTransfer"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.readyToTransfer.transferTitle')
            }}
            component={WithDrawReadyTransferModal}
        />
        <Stack.Screen
            name="MoveMoney.WithDrawFundTransferred"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.fundtransfer.transferredTitle'),
            }}
            component={WithDrawTransferredFund}
        />
        <Stack.Screen
            name="MoveMoney.ExternalCardList"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.title')
            }}
            component={ExternalCardListScreen}
        />
        <Stack.Screen
            name="MoveMoney.linkExternalCard"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.withdrawFund.exteranalCardList.addExternalCard.title')
            }}
            component={LinkExternalCard}
        />
        <Stack.Screen
            name="MoveMoney.FundMyAccountDetail"
            options={{
                headerShown: false
            }}
            component={FundMyAccountDetail}
        />
        <Stack.Screen
            name="MoveMoney.ManageTransferList"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.manageTransfer.manageUpcomingList.title')
            }}
            component={ManageTransferListScreen}
        />
        <Stack.Screen
            name="MoveMoney.ManageTransferDetail"
            options={{
                ...childScreenOptions(),
                title: i18n.t('pages.moveMoney.manageTransfer.manageTransferDetail.title')
            }}
            component={ManageTransferDetail}
        />
    </Stack.Navigator>
);

HomeStackNavigator.propTypes = {
    navigation: PropTypes.object.isRequired,
};
