import { View, useWindowDimensions } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import CurrencyList from 'currency-list';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Dropdown } from 'react-native-element-dropdown';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { isWeb } from '../../../common/utils/platform.utils';
import { CustomHeaderComponent } from '../../../navigation/screen-options.component';

export const WireTransferDetail = () => {
    const {
        params: { accounts },
    } = useRoute();

    return (
        <>
            <CustomHeaderComponent maintitle={i18n.t('pages.moveMoney.wireTransfer.wireDetailsTransfer.title')} subtitle={i18n.t('common.details')} />
            <ModalLayout>
                <WireTransferForm accounts={accounts} />
            </ModalLayout >
        </>
    );
};

const WireTransferForm = ({ accounts }) => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': '',
        }
    });

    const currencyData = CurrencyList.getAll('en_US');

    const onSubmit = (formData) => {
        const data = {
            your_account: formData.your_account
                ? accounts.find((row) => row.id === formData.your_account)
                : '',
            amount: formData.amount,
            currency: formData.currency
        };
        navigation.push('MoveMoney.beneficiaryInformationDetail', { data });
    };

    return (
        <View style={{ flex: 1 }}>
            <Controller
                control={control}
                name="your_account"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        maxHeight={200}
                        data={accounts?.map((account) => (
                            {
                                id: account.id,
                                name:
                                    account.account_type +
                                    ' ' +
                                    account.account_number,
                            }))}
                        labelField="name"
                        valueField={'id'}
                        label={i18n.t('pages.moveMoney.wireTransfer.wireDetailsTransfer.form.from')}
                        errorMessage={
                            errors.your_account && errors.your_account.message
                        }
                        placeholder={i18n.t('common.selectAccount')}
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ id }) => onChange(id)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <Controller
                control={control}
                name="amount"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(\d+)(\.?)(\d){0,2}$/,
                        message: i18n.t(
                            'pages.moveMoney.transferAccounts.accountTransfer.form.invalidAmount'
                        ),
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <Input
                        errorMessage={errors.amount && errors.amount.message}
                        errorStyle={
                            theme.ReactNativeElementsTheme.Input.errorStyle
                        }
                        label={i18n.t('pages.moveMoney.transferAccounts.accountTransfer.form.amount')}
                        textContentType="telephoneNumber"
                        dataDetectorTypes="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder={'$ 0.00'}
                        onChangeText={onChange}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />
            <Controller
                control={control}
                name="currency"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                }}
                render={({ field: { value, onChange } }) => (
                    <Input
                        InputComponent={Dropdown}
                        data={Object.entries(currencyData).map(item => ({
                            code: item[1].name + ' (' + item[1].code + ')',
                            name: item[1].name
                        }))}
                        labelField="code"
                        valueField="name"
                        label={i18n.t('pages.moveMoney.wireTransfer.wireDetailsTransfer.form.currency')}
                        errorMessage={
                            errors.currency && errors.currency.message
                        }
                        placeholder={i18n.t('common.selectCurrency')}
                        placeholderStyle={{ opacity: 0.5 }}
                        onChange={({ name }) => onChange(name)}
                        value={value}
                        style={webViewDesignSupport}
                    />
                )}
            />

            <View
                style={{
                    bottom: 20,
                    position: isWeb() ? 'fixed' : 'absolute',
                    width: width - 50,
                }}
            >
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('forms.common.continue')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </View >
    );
};

WireTransferForm.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
        account_type: PropTypes.string,
        account_number: PropTypes.string,
    }),
    accounts: PropTypes.array,
};
