import {
    ActivityIndicator,
    Image,
    ImageBackground,
    View,
    useWindowDimensions,
} from 'react-native';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import { LinearGradient } from 'expo-linear-gradient';
import { MaskedText } from 'react-native-mask-text';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import { TextGradient } from '../../../../common/ui/elements/text-gradient.element';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import theme from '../../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';

export const CreditCardTile = ({ account, card, orderCardInterceptor }) => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const cardDimensions = { height: 188, width: width * 0.88 };
    const backgroundImage =
        card.form === 'VIRTUAL'
            ? require('../../../../../assets/images/virtual_background.png')
            : require('../../../../../assets/images/physical_background.png');

    if (!card) {
        return <ActivityIndicator style={cardDimensions} />;
    }

    if (!card.id) {
        return (
            <TouchableOpacity
                onPress={() =>
                    orderCardInterceptor(() =>
                        navigation.push('Home.CreditCardSelection', { account })
                    )
                }
                style={{
                    borderRadius: 10,
                    ...cardDimensions,
                    ...theme.shadows.lightWhite,
                }}
            >
                <View
                    style={{
                        borderRadius: 10,
                        overflow: 'hidden',
                        color: 'white',
                        flex: 1,
                    }}
                >
                    <LinearGradient
                        {...theme.gradients.lightBlue}
                        style={{
                            padding: 26,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            borderRadius: 10,
                            background: '#FFFFFF',
                            boxShadow: '0px 0px 0px 1px #E0E0E0',
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                source={require('../../../../../assets/images/add-card.png')}
                                style={{
                                    width: 29,
                                    height: 29,
                                    marginRight: 8,
                                }}
                            />
                            <View>
                                <TextGradient>
                                    <Text
                                        style={{
                                            fontSize: 15,
                                            fontFamily: 'DMSans_500Medium',
                                            lineHeight: 26,
                                            letterSpacing: 0.46,
                                            color: theme.colors.phthaloBlue,
                                        }}
                                    >
                                        {i18n.t(
                                            'pages.home.manageCard.orderCard.title'
                                        )}
                                    </Text>
                                </TextGradient>
                            </View>
                        </View>
                    </LinearGradient>
                </View>
            </TouchableOpacity>
        );
    }

    return (
        <TouchableOpacity
            disabled
            style={{
                borderRadius: 10,
                ...cardDimensions,
                ...theme.shadows.lightYellow,
                background: '#FFD200',
                boxShadow:
                    '0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
            }}
        >
            <View
                style={{
                    borderRadius: 10,
                    overflow: 'hidden',
                    color: 'white',
                    flex: 1,
                }}
            >
                <ImageBackground
                    source={backgroundImage}
                    resizeMode="cover"
                    style={{
                        paddingTop: 20,
                        paddingBottom: 10,
                        paddingHorizontal: 20,
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <View style={{ flex: 1 }}>
                        {card.form === 'VIRTUAL' ? (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Image
                                    source={require('../../../../../assets/images/virtual_card.png')}
                                    style={{ width: 92, height: 25 }}
                                />
                                <Image
                                    source={require('../../../../../assets/images/registrationpage_logo.png')}
                                    style={{ width: 95, height: 25 }}
                                />
                            </View>
                        ) : (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Image
                                    source={require('../../../../../assets/images/physical_card.png')}
                                    style={{ width: 45, height: 25 }}
                                />
                                <Image
                                    source={require('../../../../../assets/images/physical_logo.png')}
                                    style={{ width: 95, height: 25 }}
                                />
                            </View>
                        )}
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                marginTop: 25,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 24,
                                    color:
                                        card.form === 'VIRTUAL'
                                            ? theme.colors.fontBackground
                                            : '#FFFFFF',
                                }}
                            >
                                <MaskedText mask="**** **** **** 9999">
                                    {card.last_four}
                                </MaskedText>
                            </Text>
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 14,
                                    fontFamily: 'DMSans_500Medium',
                                    lineHeight: 22,
                                    letterSpacing: 0.1,
                                    color:
                                        card.form === 'VIRTUAL'
                                            ? theme.colors.fontBackground
                                            : '#FFFFFF',
                                }}
                            >
                                {card.expiration_month}/{card.expiration_year}
                            </Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <View style={{ flex: 1 }}>
                                <Text
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'DMSans_500Medium',
                                        lineHeight: 22,
                                        letterSpacing: 0.1,
                                        color:
                                            card.form === 'VIRTUAL'
                                                ? theme.colors.fontBackground
                                                : '#FFFFFF',
                                    }}
                                >
                                    {card.emboss_name.line_1}
                                </Text>
                                {card.emboss_name.line_2 && (
                                    <Text
                                        style={{
                                            fontSize: 14,
                                            lineHeight: 22,
                                            letterSpacing: 0.1,
                                            fontFamily: 'DMSans_500Medium',
                                            color:
                                                card.form === 'VIRTUAL'
                                                    ? theme.colors.fontBackground
                                                    : '#FFFFFF',
                                        }}
                                    >
                                        {card.emboss_name.line_2}
                                    </Text>
                                )}
                            </View>
                            <Icon
                                Component={() => (
                                    <CreditCardBrandLogo
                                        brand={card.card_brand}
                                        type={card.type}
                                    />
                                )}
                            />
                        </View>
                    </View>
                </ImageBackground>
            </View>
        </TouchableOpacity>
    );
};

CreditCardTile.propTypes = {
    account: PropTypes.shape({
        account_type: PropTypes.string,
    }),
    card: PropTypes.shape({
        id: PropTypes.string,
        card_brand: PropTypes.string,
        type: PropTypes.string,
        expiration_month: PropTypes.string,
        expiration_year: PropTypes.string,
        last_four: PropTypes.string,
        emboss_name: PropTypes.shape({
            line_1: PropTypes.string,
            line_2: PropTypes.string,
        }),
        form: PropTypes.string,
    }),
    onPress: PropTypes.func,
    orderCardInterceptor: PropTypes.func,
};

const CreditCardBrandLogo = ({ brand, type }) => {
    if (brand === 'MASTERCARD' && type === 'DEBIT') {
        return (
            <Image
                source={require('../../../../../assets/images/mc_symbol.png')}
                style={{ width: 60, height: 42, alignSelf: 'flex-end' }}
            />
        );
    }

    return (
        <Image
            source={require('../../../../../assets/images/creditcard.png')}
            style={{ width: 46, height: 28, alignSelf: 'flex-end' }}
        />);
};

CreditCardBrandLogo.propTypes = {
    brand: PropTypes.string,
    type: PropTypes.string,
};
