import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
// import theme from '../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';

export const AlreadyMemberLink = ({ textNote, textLink }) => {
    const navigation = useNavigation();
    return (
        <TouchableOpacity
            style={{
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: 57,
                outlineStyle: isWeb() ? 'none' : null,
            }}
            onPress={() => navigation.navigate('Auth.SignIn')}
        >
            <Text
                style={{
                    fontFamily: 'DMSans_400Regular',
                    color: textNote,
                    fontSize: 16,
                    lineHeight: 24,
                    textAlign: 'center',
                    letterSpacing: 0.15,
                }}
            >
                {i18n.t('common.alreadyMemberText')}&nbsp;
            </Text>
            <Text
                style={{
                    fontFamily: 'DMSans_500Medium',
                    color: textLink,
                    fontSize: 14,
                    lineHeight: 24,
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                }}
            >
                {i18n.t('common.alreadyMemberLink')}
            </Text>
        </TouchableOpacity>
    );
};

AlreadyMemberLink.propTypes = {
    textNote: PropTypes.string,
    textLink: PropTypes.string,
};
