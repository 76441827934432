import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { Button, Input } from 'react-native-elements';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import { api } from '../../../../App.context';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { Dropdown } from 'react-native-element-dropdown';
import MaskInput from 'react-native-mask-input';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';

export const LineOfCreditAccount = () => {
    const navigation = useNavigation();
    const [showPassword, setShowPassword] = useState(false);

    const toast = useToast();
    const {
        params: { selectedProductId }
    } = useRoute();

    const navigateToHome = () => {
        navigation.push('Home');
        toast.show(i18n.t('pages.home.accounts.successBody'), {
            type: 'successWithTitle',
            data: {
                title: i18n.t('pages.home.accounts.successTitle'),
            },
        });
    };

    const openAccount = () => {
        return api
            .post('/accounts', {
                body: {
                    account_template_id: selectedProductId,
                },
            })
            .then(() => {
                navigateToHome();
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    const {
        control,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'amount': ''
        }
    });

    const ssnMask = [
        [/[0-9]/],
        [/[0-9]/],
        [/[0-9]/],
        '-',
        [/[0-9]/],
        [/[0-9]/],
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
    ];

    const incomeTypeValue = [
        {
            "incomeValue": "Primary",
            "id": 1
        },
        {
            "incomeValue": "Secondary",
            "id": 2
        }
    ]

    const incomeFrequencyValue = [
        {
            "frequency": "Once",
            "id": 1
        },
        {
            "frequency": "Weekly",
            "id": 2
        },
        {
            "frequency": "Bi-weekly",
            "id": 3
        },
        {
            "frequency": "Monthly",
            "id": 4
        },
    ]

    const employeeDetail = [
        {
            "status": "Employed",
            "id": 1
        },
        {
            "status": "UnEmployed",
            "id": 2
        }
    ]

    return (
        <ModalLayout
            title={i18n.t('pages.accountSelection.lineofCreditAccount.title')}
            subtitle={i18n.t('pages.accountSelection.lineofCreditAccount.subtitle')}
        >
            <View style={{ flex: 1 }}>

                <Controller
                    control={control}
                    name="ssn"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                        pattern: {
                            value: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
                            message: i18n.t('forms.ssn.invalidFormat'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={MaskInput}
                            mask={ssnMask}
                            secureTextEntry={!showPassword}
                            showObfuscatedValue
                            obfuscationCharacter="*"
                            errorMessage={errors.ssn && errors.ssn.message}
                            keyboardType="numeric"
                            label={i18n.t('forms.ssn.label')}
                            placeholder={i18n.t('forms.ssn.placeholder')}
                            onChangeText={(masked) => {
                                onChange(masked);
                            }}
                            onBlur={onBlur}
                            value={value}
                            rightIcon={{
                                type: 'material-community',
                                name: showPassword
                                    ? 'eye-off-outline'
                                    : 'eye-outline',
                                onPress: () => setShowPassword(!showPassword),
                            }}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="incomeType"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={incomeTypeValue?.map((row) => row)}
                            labelField="incomeValue"
                            valueField="incomeValue"
                            errorMessage={
                                errors.incomeType &&
                                errors.incomeType.message
                            }
                            label={i18n.t('pages.accountSelection.lineofCreditAccount.locDetails.incomeType')}
                            onChange={({ incomeValue }) => onChange(incomeValue)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="incomeFrequency"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={incomeFrequencyValue?.map((row) => row)}
                            labelField="frequency"
                            valueField="frequency"
                            errorMessage={
                                errors.incomeFrequency &&
                                errors.incomeFrequency.message
                            }
                            label={i18n.t('pages.accountSelection.lineofCreditAccount.locDetails.incomeFrequency')}
                            onChange={({ frequency }) => onChange(frequency)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="amount"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                        pattern: {
                            value: /^(\d+)(\.?)(\d){0,2}$/,
                            message: i18n.t(
                                'pages.home.makeDeposit.form.invalidAmount'
                            ),
                        },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            errorMessage={errors.amount && errors.amount.message}
                            errorStyle={
                                theme.ReactNativeElementsTheme.Input.errorStyle
                            }
                            label={i18n.t('pages.accountSelection.lineofCreditAccount.locDetails.annualIncome')}
                            textContentType="telephoneNumber"
                            dataDetectorTypes="phoneNumber"
                            keyboardType="phone-pad"
                            placeholder={'$ 0.00'}
                            onChangeText={onChange}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="employeeStatus"
                    rules={{
                        required: {
                            value: 'true',
                            message: i18n.t('forms.common.required'),
                        },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                            InputComponent={Dropdown}
                            data={employeeDetail?.map((row) => row)}
                            labelField="status"
                            valueField="status"
                            errorMessage={
                                errors.employeeStatus &&
                                errors.employeeStatus.message
                            }
                            label={i18n.t('pages.accountSelection.lineofCreditAccount.locDetails.employmentStatus')}
                            onChange={({ status }) => onChange(status)}
                            onBlur={onBlur}
                            value={value}
                            style={webViewDesignSupport}
                        />
                    )}
                />

                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    title={i18n.t('pages.accountSelection.lineofCreditAccount.locDetails.submitApplication')}
                    onPress={() => openAccount()}
                />
            </View>
        </ModalLayout>
    );
};
