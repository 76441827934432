import React from 'react';
import { Text } from 'react-native-elements';
import { View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { topScreenOptions } from '../../navigation/screen-options.component';

const Stack = createNativeStackNavigator();

export const MoreStackNavigator = () => (
    <Stack.Navigator screenOptions={{ ...topScreenOptions() }}>
        <Stack.Screen name="More.Home" component={MoreScreen} />
    </Stack.Navigator>
);

const MoreScreen = () => (
    <View
        style={{
            marginTop: 250,
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
        }}
    >
        <Text style={{ marginBottom: 50 }}>{i18n.t('common.comingSoon')}</Text>
    </View>
);
