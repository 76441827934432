import { Text, View } from 'react-native';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

export const InformationDetail = ({ message }) => (
    <View
        style={{
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
            paddingHorizontal: 16,
            paddingVertical: 6,
            marginBottom: 10,
            backgroundColor: theme.colors.shadWhite,
            borderColor: theme.colors.infoToastBorder,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
        }}
    >
        <View style={{ marginRight: 12 }}>
            <Icon
                name="alert-circle-outline"
                type="ionicon"
                color={theme.colors.infoToastBorder}
            />
        </View>
        <View
            style={{
                width: '90%',
                maxWidth: '90%',
            }}
        >
            <Text
                style={{
                    fontSize: 14,
                    fontFamily: 'DMSans_400Regular',
                    lineHeight: 20,
                    letterSpacing: 0.15,
                    color: theme.colors.infoText
                }}
            >
                {message}
            </Text>
        </View>
    </View>
);

InformationDetail.propTypes = {
    message: PropTypes.string,
};
