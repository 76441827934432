import { View, useWindowDimensions } from 'react-native';
import { BlurView } from 'expo-blur';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import theme from '../theme';

export const ModalLayout = ({ small, title, subtitle, children }) => {
    const { height } = useWindowDimensions();

    if (!small) {
        return (
            <KeyboardAwareScrollView
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
            >
                <View
                    style={{
                        padding: 25,
                        minHeight: height - 80,
                    }}
                >
                    {title && (
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 20,
                                marginBottom: subtitle ? 30 : 60,
                                marginTop: 30,
                                textAlign: 'center',
                                color: theme.colors.textHeading,
                            }}
                        >
                            {title}
                        </Text>
                    )}
                    {subtitle && (
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 20,
                                marginBottom: 24,
                            }}
                        >
                            {subtitle}
                        </Text>
                    )}

                    {children}
                </View>
            </KeyboardAwareScrollView>
        );
    }
    return (
        <BlurView
            intensity={40}
            tint="dark"
            style={{
                justifyContent: 'center',
                flex: 1,
            }}
        >
            <View
                style={{
                    backgroundColor: 'white',
                    marginHorizontal: 16,
                    marginBottom: 100,
                    borderRadius: 8,
                    padding: 24,
                    shadowColor: '#666',
                    shadowRadius: 10,
                    shadowOffset: {
                        height: 5,
                    },
                    shadowOpacity: 1,
                }}
            >
                {children}
            </View>
        </BlurView>
    );
};

ModalLayout.propTypes = {
    small: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.any,
};
