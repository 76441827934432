import { Button, Text } from 'react-native-elements';
import { View, useWindowDimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../common/ui/components/modal-layout.component';
import React from 'react';
import i18n from 'i18n-js';
import { isWeb } from '../../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../../common/ui/theme';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaskedText } from 'react-native-mask-text';
import { TextGradient } from '../../../common/ui/elements/text-gradient.element';
import { TextCurrency } from '../../../common/ui/elements/text-currency.element';

export const WithDrawTransferredFund = () => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const today = moment().toDate();

    const {
        params: { data },
    } = useRoute();

    const accountFromBalance = data?.fromAccount?.balances?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );

    const accountToBalance = data?.toAccount?.balances?.find(
        (b) => b.type === 'ACCOUNT_BALANCE'
    );

    return (
        <ModalLayout>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.withdrawFund.fundtransfer.form.amount')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {'$' + data.amount + '.00'}
                    </Text>
                </View>
                {data?.when && (
                    <View
                        style={{
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <Text style={theme.styles.textBold}>
                            {i18n.t('pages.moveMoney.withdrawFund.fundtransfer.form.submit')}
                        </Text>
                        <Text style={theme.styles.textRegular}>
                            {moment(new Date(today)).format('MMM DD, YYYY h:mm A')}
                        </Text>
                    </View>
                )}
                {data?.when && (
                    <View
                        style={{
                            flexDirection: 'column',
                            marginBottom: 15,
                        }}
                    >
                        <Text style={theme.styles.textBold}>
                            {i18n.t('pages.moveMoney.withdrawFund.fundtransfer.form.when')}
                        </Text>
                        <Text style={theme.styles.textRegular}>
                            {moment(data?.when).format('MMMM DD, YYYY')}
                        </Text>
                    </View>
                )}
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.withdrawFund.fundtransfer.form.from')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.fromAccount?.account_type} {' '}
                        <MaskedText mask="**** **** **** 9999">
                            {data?.fromAccount?.account_number}
                        </MaskedText>
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountFromBalance &&
                                accountFromBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View style={{ flexDirection: 'column', marginBottom: 15 }}>
                    <Text style={theme.styles.textBold}>
                        {i18n.t('pages.moveMoney.withdrawFund.fundtransfer.form.to')}
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.toAccount?.type} {' '}
                        <MaskedText mask="**** **** **** 9999">
                            {data?.toAccount?.vendor_data.account_number_mask}
                        </MaskedText>
                    </Text>
                    <Text style={theme.styles.textRegular}>
                        {data?.toAccount.routing_identifiers.bank_name}
                    </Text>
                    <TextGradient style={theme.styles.textGradient}>
                        <TextCurrency
                            value={
                                accountToBalance &&
                                accountToBalance.balance / 100
                            }
                        />
                    </TextGradient>
                </View>
                <View
                    style={{
                        bottom: 20,
                        position: isWeb() ? 'fixed' : 'absolute',
                        width: width - 50,
                    }}
                >
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        loadingProps={{
                            color: theme.colors.textHeading,
                        }}
                        title={i18n.t('common.backToAccount')}
                        onPress={() => navigation.push('Home')}
                    />
                    <TouchableOpacity onPress={() => navigation.push('Home')}>
                        <Text style={theme.styles.navigationButton}>
                            {i18n.t('common.anotherTransfer')}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ModalLayout>
    );
};


