import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Input } from 'react-native-elements';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import moment from 'moment';
import { isWeb } from '../../../common/utils/platform.utils';

const WEBCSS = isWeb() ? React.lazy(() => import('../web-picker.css')) : null;

export const WebDatePicker = ({
    label,
    date,
    minimumDate,
    maximumDate,
    onChange,
}) => {
    const textFieldRef = useRef(null);

    return (
        <>
            <React.Suspense fallback={<></>}>
                {<WEBCSS />}
            </React.Suspense>
            <DatePicker
                selected={date}
                placeholderText="Select Date"
                onChange={(date) => { onChange(moment(date).valueOf()) }}
                minDate={minimumDate}
                maxDate={maximumDate}
                customInput={
                    <Input
                        label={label}
                        ref={textFieldRef}
                        placeholder={i18n.t('common.selectDate')}
                        onFocus={() => {
                            textFieldRef.current.blur();
                        }}
                        value={date}
                        style={{ width: '100%', maxWidth: '100%', outlineStyle: 'none' }}
                        rightIcon={{
                            name: 'keyboard-arrow-down',
                        }}
                    />
                }
            />
        </>
    );
};

WebDatePicker.propTypes = {
    label: PropTypes.string,
    date: PropTypes.any,
    minimumDate: PropTypes.instanceOf(Date),
    maximumDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
};
