import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import React from 'react';
import { Image, Text } from 'react-native-elements';
import { View, TouchableOpacity } from 'react-native';
import { isWeb } from '../../utils/platform.utils';
import theme from '../theme';

export const SendMoneyOutAccount = ({ product, selected, onSelect }) => {
    return (
        <TouchableOpacity
            onPress={() => onSelect(product.id)}
            activeOpacity={0.5}
            style={!isWeb() ? { ...theme.shadows.lightBlue } : null}
        >
            <LinearGradient
                {...theme.gradients.lightBlue}
                style={{
                    justifyContent: 'center',
                    borderWidth: 2,
                    boxShadow: selected
                        ? '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'
                        : null,
                    borderColor: selected
                        ? theme.colors.phthaloBlue
                        : 'rgba(0,0,0,0.2)',
                    backgroundColor: '#FFFFFF',
                    marginBottom: 10,
                    borderRadius: 10,
                    padding: 10,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        maxWidth: '100%'
                    }}
                >
                    <Text
                        style={{
                            fontSize: 17,
                            flex: 1,
                            fontFamily: 'DMSans_700Bold',
                            marginRight: 25
                        }}
                    >
                        {product.name}
                    </Text>
                    <Image
                        source={require('../.././../../assets/images/send.png')}
                        style={{ width: 14, height: 14 }}
                    />
                </View>
            </LinearGradient>
        </TouchableOpacity>
    );
};

SendMoneyOutAccount.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
};
