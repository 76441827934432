import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { View } from 'react-native';

import theme from '../theme';

export const Pill = ({ label, active, onPress }) => {
    return (
        <TouchableOpacity
            style={{ marginRight: 10 }}
            onPress={(event) => onPress && onPress(event)}
        >
            <View
                style={{
                    borderRadius: 20,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderWidth: active ? 0 : 1,
                    backgroundColor: active ? '#FFD200' : 'transparent',
                    borderColor: active ? '#FFD200' : 'rgba(0,0,0,0.2)',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        fontSize: 13,
                        lineHeight: 18,
                        letterSpacing: 0.16,
                        color: theme.colors.fontBackground,
                        opacity: 0.87,
                        fontFamily: 'DMSans_400Regular',
                    }}
                >
                    {label}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

Pill.propTypes = {
    label: PropTypes.string,
    active: PropTypes.bool,
    onPress: PropTypes.func,
};
