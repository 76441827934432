import { AccountsStackNavigator } from '../screens/accounts/accounts.navigator';
import { HomeStackNavigator } from '../screens/home/home.navigator';
import { MoreStackNavigator } from '../screens/more/more.navigator';
import { MoveMoneyStackNavigator } from '../screens/move-money/move-money.navigator';
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

export const TabNavigator = () => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                name="Tab.Home"
                options={{
                    headerShown: false,
                }}
                component={HomeStackNavigator}
            />
            <Stack.Screen
                name="Tab.Accounts"
                options={{
                    headerShown: false,
                }}
                component={AccountsStackNavigator}
            />
            <Stack.Screen
                name="Tab.MoveMoney"
                options={{
                    headerShown: false,
                }}
                component={MoveMoneyStackNavigator}
            />
            <Stack.Screen
                name="Tab.More"
                options={{
                    headerShown: false,
                }}
                component={MoreStackNavigator}
            />
        </Stack.Navigator>
    );
};
