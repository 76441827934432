import { Image, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollableScreenLayout } from '../../../common/ui/components/screen-layout.component';
import i18n from 'i18n-js';
import theme from '../../../common/ui/theme';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button, Input, Text } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'react-native-element-dropdown';
import { webViewDesignSupport } from '../../../common/utils/secure-store.utils';
import { isAndroid, isWeb } from '../../../common/utils/platform.utils';
import { MainContext } from '../../../../App.context';
import { CustomHeaderComponent } from '../../../navigation/screen-options.component';

export const BeneficiaryInformation = () => {
    const navigation = useNavigation();
    const { width } = useWindowDimensions();

    const {
        params: { data },
    } = useRoute();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            'state_of_purpose': '',
        }
    });

    const onSubmit = (formData) => {
        const beneficiaryData = {
            state_of_purpose: formData.state_of_purpose,
            beneficiary_info: formData.beneficiary_info,
            memo: formData.memo ? formData.memo : ""
        };
        navigation.push('MoveMoney.WireReadyTransfer', { beneficiaryData, data });
    };

    return (
        <MainContext.Consumer>
            {({ beneficiaryProfile }) => (
                <>
                    <CustomHeaderComponent maintitle={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.title')} subtitle={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.subtitle')} />
                    <ScrollableScreenLayout>
                        <Container>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Text style={theme.styles.textBold}>
                                    {i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.title')}
                                </Text>
                                <Text style={theme.styles.textBold}>
                                    {beneficiaryProfile?.beneficiaryName}
                                </Text>
                                <Text style={theme.styles.textBold}>
                                    {beneficiaryProfile?.accountNumber}
                                </Text>
                            </View>
                            <TouchableOpacity
                                onPress={() =>
                                    navigation.push('MoveMoney.editBeneficiaryInfo', {
                                        beneficiaryProfile,
                                        data
                                    })
                                }
                            >
                                <View
                                    accessibility
                                    accessibilityLabel={i18n.t(
                                        'pages.moveMoney.wireTransfer.beneficiaryDetails.editbeneficiary.accessibility.button'
                                    )}
                                >
                                    <Image
                                        source={require('../../../../assets/images/edit.png')}
                                        style={{
                                            width: 22,
                                            height: 22,
                                        }}
                                    />
                                </View>
                            </TouchableOpacity>
                        </Container>
                        <View style={{ flex: 1 }}>
                            {/* Purpose will be change */}
                            <Controller
                                control={control}
                                name="state_of_purpose"
                                rules={{
                                    required: {
                                        value: 'true',
                                        message: i18n.t('forms.common.required'),
                                    },
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        InputComponent={Dropdown}
                                        data={Array(10).fill("purpose").map(item => ({
                                            code: item,
                                            name: item
                                        }))}
                                        labelField="name"
                                        valueField={'name'}
                                        label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.purpose')}
                                        errorMessage={
                                            errors.state_of_purpose && errors.state_of_purpose.message
                                        }
                                        placeholder={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.select')}
                                        placeholderStyle={{ opacity: 0.5 }}
                                        onChange={({ name }) => onChange(name)}
                                        value={value}
                                        style={webViewDesignSupport}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="beneficiary_info"
                                rules={{
                                    required: {
                                        value: 'true',
                                        message: i18n.t('forms.common.required'),
                                    },
                                }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Input
                                        multiline={true}
                                        numberOfLines={5}
                                        errorMessage={
                                            errors.beneficiary_info && errors.beneficiary_info.message
                                        }
                                        label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.beneficiaryInfo')}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        style={isWeb() ? { outlineStyle: 'none' } : isAndroid ? { textAlignVertical: "top" } : null}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="memo"
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        multiline={true}
                                        numberOfLines={5}
                                        errorMessage={errors.memo && errors.memo.message}
                                        errorStyle={
                                            theme.ReactNativeElementsTheme.Input.errorStyle
                                        }
                                        label={i18n.t('pages.moveMoney.wireTransfer.beneficiaryDetails.benficiaryInformation.memo')}
                                        onChangeText={onChange}
                                        value={value}
                                        style={isWeb() ? { outlineStyle: 'none' } : isAndroid ? { textAlignVertical: "top" } : null}
                                    />
                                )}
                            />
                            <View
                                style={isWeb() ?
                                    {
                                        marginTop: 15,
                                        marginBottom: 15,
                                    } :
                                    {
                                        marginTop: 30,
                                        marginBottom: 15,
                                    }}
                            >
                                <Button
                                    buttonStyle={theme.styles.buttonStylePrimary}
                                    titleStyle={theme.styles.titleStylePrimary}
                                    title={i18n.t('forms.common.continue')}
                                    onPress={handleSubmit(onSubmit)}
                                />
                            </View>
                        </View>
                    </ScrollableScreenLayout>
                </>
            )}
        </MainContext.Consumer>
    );
};

/*
    A clickable, bordered box containing a section of the beneficiaryProfile
*/
const Container = ({ children }) => (
    <View
        style={{
            width: '100%',
            marginBottom: 20,
            borderWidth: 1,
            borderStyle: 'solid',
            paddingVertical: 15,
            paddingHorizontal: 10,
            borderColor: '#00008E',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        {children}
    </View>
);

Container.propTypes = {
    children: PropTypes.any,
};
