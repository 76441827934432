import React, { useState } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import { RefreshControl } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export const ScrollableScreenLayout = ({
    fetchAccounts,
    refresh,
    children,
}) => {
    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        fetchAccounts();
        setRefreshing(false);
    }, []);

    return (
        <KeyboardAwareScrollView
            nestedScrollEnabled={true}
            refreshControl={
                refresh ? (
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                    />
                ) : null
            }
        >
            <ScreenLayout>{children}</ScreenLayout>
        </KeyboardAwareScrollView>
    );
};

ScrollableScreenLayout.defaultProps = {
    refresh: false,
};

ScrollableScreenLayout.propTypes = {
    children: PropTypes.any,
    fetchAccounts: PropTypes.func,
    refresh: PropTypes.bool,
};

export const ScreenLayout = ({ children }) => (
    <SafeAreaView
        edges={['right', 'left', 'bottom']}
        style={{
            paddingVertical: 25,
            paddingHorizontal: 25,
            flex: 1,
        }}
    >
        {children}
    </SafeAreaView>
);

ScreenLayout.propTypes = {
    children: PropTypes.any,
};
